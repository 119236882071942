<style src="./Colors.scss" global>:global([cm-color='Primary / CDLLife Blue']) {
  color: #2e5ff6 ; }

:global([cm-background='Primary / CDLLife Blue']) {
  background: #2e5ff6 ; }

:global([cm-border='Primary / CDLLife Blue']) {
  border-color: #2e5ff6 ;
  border-style: solid ; }

:global([cm-color-i='Primary / CDLLife Blue']) {
  color: #2e5ff6 !important; }

:global([cm-background-i='Primary / CDLLife Blue']) {
  background: #2e5ff6 !important; }

:global([cm-border-i='Primary / CDLLife Blue']) {
  border-color: #2e5ff6 !important;
  border-style: solid !important; }

:global([cm-color='Secondary / Dodger Blue']) {
  color: #3880ff ; }

:global([cm-background='Secondary / Dodger Blue']) {
  background: #3880ff ; }

:global([cm-border='Secondary / Dodger Blue']) {
  border-color: #3880ff ;
  border-style: solid ; }

:global([cm-color-i='Secondary / Dodger Blue']) {
  color: #3880ff !important; }

:global([cm-background-i='Secondary / Dodger Blue']) {
  background: #3880ff !important; }

:global([cm-border-i='Secondary / Dodger Blue']) {
  border-color: #3880ff !important;
  border-style: solid !important; }

:global([cm-color='Neutral / Mine Shaft']) {
  color: #262626 ; }

:global([cm-background='Neutral / Mine Shaft']) {
  background: #262626 ; }

:global([cm-border='Neutral / Mine Shaft']) {
  border-color: #262626 ;
  border-style: solid ; }

:global([cm-color-i='Neutral / Mine Shaft']) {
  color: #262626 !important; }

:global([cm-background-i='Neutral / Mine Shaft']) {
  background: #262626 !important; }

:global([cm-border-i='Neutral / Mine Shaft']) {
  border-color: #262626 !important;
  border-style: solid !important; }

:global([cm-color='Neutral / Storm Grey']) {
  color: #73767f ; }

:global([cm-background='Neutral / Storm Grey']) {
  background: #73767f ; }

:global([cm-border='Neutral / Storm Grey']) {
  border-color: #73767f ;
  border-style: solid ; }

:global([cm-color-i='Neutral / Storm Grey']) {
  color: #73767f !important; }

:global([cm-background-i='Neutral / Storm Grey']) {
  background: #73767f !important; }

:global([cm-border-i='Neutral / Storm Grey']) {
  border-color: #73767f !important;
  border-style: solid !important; }

:global([cm-color='Neutral / Manatee']) {
  color: #989aa2 ; }

:global([cm-background='Neutral / Manatee']) {
  background: #989aa2 ; }

:global([cm-border='Neutral / Manatee']) {
  border-color: #989aa2 ;
  border-style: solid ; }

:global([cm-color-i='Neutral / Manatee']) {
  color: #989aa2 !important; }

:global([cm-background-i='Neutral / Manatee']) {
  background: #989aa2 !important; }

:global([cm-border-i='Neutral / Manatee']) {
  border-color: #989aa2 !important;
  border-style: solid !important; }

:global([cm-color='Neutral / Mercury']) {
  color: #e1e1e1 ; }

:global([cm-background='Neutral / Mercury']) {
  background: #e1e1e1 ; }

:global([cm-border='Neutral / Mercury']) {
  border-color: #e1e1e1 ;
  border-style: solid ; }

:global([cm-color-i='Neutral / Mercury']) {
  color: #e1e1e1 !important; }

:global([cm-background-i='Neutral / Mercury']) {
  background: #e1e1e1 !important; }

:global([cm-border-i='Neutral / Mercury']) {
  border-color: #e1e1e1 !important;
  border-style: solid !important; }

:global([cm-color='Neutral / Athens Grey']) {
  color: #f4f5f8 ; }

:global([cm-background='Neutral / Athens Grey']) {
  background: #f4f5f8 ; }

:global([cm-border='Neutral / Athens Grey']) {
  border-color: #f4f5f8 ;
  border-style: solid ; }

:global([cm-color-i='Neutral / Athens Grey']) {
  color: #f4f5f8 !important; }

:global([cm-background-i='Neutral / Athens Grey']) {
  background: #f4f5f8 !important; }

:global([cm-border-i='Neutral / Athens Grey']) {
  border-color: #f4f5f8 !important;
  border-style: solid !important; }

:global([cm-color='Neutral / White']) {
  color: #ffffff ; }

:global([cm-background='Neutral / White']) {
  background: #ffffff ; }

:global([cm-border='Neutral / White']) {
  border-color: #ffffff ;
  border-style: solid ; }

:global([cm-color-i='Neutral / White']) {
  color: #ffffff !important; }

:global([cm-background-i='Neutral / White']) {
  background: #ffffff !important; }

:global([cm-border-i='Neutral / White']) {
  border-color: #ffffff !important;
  border-style: solid !important; }

:global([cm-color='White']) {
  color: #ffffff ; }

:global([cm-background='White']) {
  background: #ffffff ; }

:global([cm-border='White']) {
  border-color: #ffffff ;
  border-style: solid ; }

:global([cm-color-i='White']) {
  color: #ffffff !important; }

:global([cm-background-i='White']) {
  background: #ffffff !important; }

:global([cm-border-i='White']) {
  border-color: #ffffff !important;
  border-style: solid !important; }

:global([cm-color='Accent / Warning']) {
  color: #ffbd55 ; }

:global([cm-background='Accent / Warning']) {
  background: #ffbd55 ; }

:global([cm-border='Accent / Warning']) {
  border-color: #ffbd55 ;
  border-style: solid ; }

:global([cm-color-i='Accent / Warning']) {
  color: #ffbd55 !important; }

:global([cm-background-i='Accent / Warning']) {
  background: #ffbd55 !important; }

:global([cm-border-i='Accent / Warning']) {
  border-color: #ffbd55 !important;
  border-style: solid !important; }

:global([cm-color='Accent / Danger']) {
  color: #ea4335 ; }

:global([cm-background='Accent / Danger']) {
  background: #ea4335 ; }

:global([cm-border='Accent / Danger']) {
  border-color: #ea4335 ;
  border-style: solid ; }

:global([cm-color-i='Accent / Danger']) {
  color: #ea4335 !important; }

:global([cm-background-i='Accent / Danger']) {
  background: #ea4335 !important; }

:global([cm-border-i='Accent / Danger']) {
  border-color: #ea4335 !important;
  border-style: solid !important; }

:global([cm-color='Accent / Success']) {
  color: #009859 ; }

:global([cm-background='Accent / Success']) {
  background: #009859 ; }

:global([cm-border='Accent / Success']) {
  border-color: #009859 ;
  border-style: solid ; }

:global([cm-color-i='Accent / Success']) {
  color: #009859 !important; }

:global([cm-background-i='Accent / Success']) {
  background: #009859 !important; }

:global([cm-border-i='Accent / Success']) {
  border-color: #009859 !important;
  border-style: solid !important; }
</style>
