<style src="./Font.scss" global>:global([cm-font='Title / 28 Bold']) {
  font-style: normal ;
  font-weight: bold ;
  font-size: 28px ;
  line-height: 36px ; }

:global([cm-font-i='Title / 28 Bold']) {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 28px !important;
  line-height: 36px !important; }

:global(p[cm-font='Title / 28 Bold']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 28 Bold']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Title / 24 Semibold']) {
  font-style: normal ;
  font-weight: 600 ;
  font-size: 24px ;
  line-height: 32px ; }

:global([cm-font-i='Title / 24 Semibold']) {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important; }

:global(p[cm-font='Title / 24 Semibold']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 24 Semibold']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Title / 20 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 20px ;
  line-height: 24px ; }

:global([cm-font-i='Title / 20 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important; }

:global(p[cm-font='Title / 20 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 20 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Title / 16 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 16px ;
  line-height: 20px ; }

:global([cm-font-i='Title / 16 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important; }

:global(p[cm-font='Title / 16 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 16 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Title / 14 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 14px ;
  line-height: 20px ; }

:global([cm-font-i='Title / 14 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important; }

:global(p[cm-font='Title / 14 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 14 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Title / 12 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 12px ;
  line-height: 16px ; }

:global([cm-font-i='Title / 12 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important; }

:global(p[cm-font='Title / 12 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Title / 12 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 16 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 16px ;
  line-height: 20px ; }

:global([cm-font-i='Body / 16 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important; }

:global(p[cm-font='Body / 16 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 16 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 16 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 16px ;
  line-height: 20px ; }

:global([cm-font-i='Body / 16 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important; }

:global(p[cm-font='Body / 16 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 16 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 14 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 14px ;
  line-height: 20px ; }

:global([cm-font-i='Body / 14 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important; }

:global(p[cm-font='Body / 14 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 14 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 14 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 14px ;
  line-height: 20px ; }

:global([cm-font-i='Body / 14 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important; }

:global(p[cm-font='Body / 14 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 14 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 12 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 12px ;
  line-height: 16px ; }

:global([cm-font-i='Body / 12 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important; }

:global(p[cm-font='Body / 12 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 12 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Body / 12 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 12px ;
  line-height: 16px ; }

:global([cm-font-i='Body / 12 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important; }

:global(p[cm-font='Body / 12 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Body / 12 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Button / 16 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 16px ;
  line-height: 16px ; }

:global([cm-font-i='Button / 16 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important; }

:global(p[cm-font='Button / 16 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Button / 16 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Button / 16 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 16px ;
  line-height: 16px ; }

:global([cm-font-i='Button / 16 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important; }

:global(p[cm-font='Button / 16 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Button / 16 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Button / 14 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 14px ;
  line-height: 16px ; }

:global([cm-font-i='Button / 14 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important; }

:global(p[cm-font='Button / 14 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Button / 14 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Button / 14 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 14px ;
  line-height: 16px ; }

:global([cm-font-i='Button / 14 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important; }

:global(p[cm-font='Button / 14 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Button / 14 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Button / 12 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 12px ;
  line-height: 16px ; }

:global([cm-font-i='Button / 12 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important; }

:global(p[cm-font='Button / 12 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Button / 12 Medium']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Link / 12 Regular']) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 12px ;
  line-height: 16px ; }

:global([cm-font-i='Link / 12 Regular']) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important; }

:global(p[cm-font='Link / 12 Regular']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Link / 12 Regular']:last-child) {
    margin-bottom: 0; }

:global([cm-font='Link / 14 Medium']) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 14px ;
  line-height: 16px ; }

:global([cm-font-i='Link / 14 Medium']) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important; }

:global(p[cm-font='Link / 14 Medium']) {
  margin-top: 0;
  margin-bottom: 0.1em; }
  :global(p[cm-font='Link / 14 Medium']:last-child) {
    margin-bottom: 0; }
</style>
