<style src="./Dotnav.scss" global>:global(.cm-DotNav) {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }
  :global(.cm-DotNav) :global(.cm-DotNav--small) {
    position: relative !important;
    top: 1px !important; }
    :global(.cm-DotNav) :global(.cm-DotNav--small) :global(a) {
      width: 3px !important;
      height: 3px !important; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
:global(.cm-DotNav) > :global(*) {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 5px; }

:global(.cm-DotNav) > :global(*) > :global(*) {
  display: block;
  box-sizing: border-box;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #989aa2;
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #989aa2;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border-color; }

/* Hover + Focus */
:global(.cm-DotNav) > :global(*) > :global(:hover),
:global(.cm-DotNav) > :global(*) > :global(:focus) {
  background-color: #989aa2;
  outline: none;
  border-color: #989aa2; }

/* Active */
:global(.cm-DotNav) > :global(.cm-Active) > :global(*) {
  background-color: #2e5ff6;
  border-color: #2e5ff6; }

/* Modifier: 'cm-DotNav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
:global(.cm-DotNav--vertical) {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
:global(.cm-DotNav--vertical) > :global(*) {
  padding-left: 0;
  padding-top: 12px; }
</style>
