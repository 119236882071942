<style src="./Margin.scss" global>/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
:global(.cm-Margin) {
  margin-bottom: 16px; }

:global(*) + :global(.cm-Margin) {
  margin-top: 16px !important; }

:global(.cm-Margin--top) {
  margin-top: 16px !important; }

:global(.cm-Margin--bottom) {
  margin-bottom: 16px !important; }

:global(.cm-Margin--left) {
  margin-left: 16px !important; }

:global(.cm-Margin--right) {
  margin-right: 16px !important; }

/* Small
 ========================================================================== */
:global(.cm-Margin--small) {
  margin-bottom: 12px; }

:global(*) + :global(.cm-Margin--small) {
  margin-top: 12px !important; }

:global(.cm-Margin--small-top) {
  margin-top: 12px !important; }

:global(.cm-Margin--small-bottom) {
  margin-bottom: 12px !important; }

:global(.cm-Margin--small-left) {
  margin-left: 12px !important; }

:global(.cm-Margin--small-right) {
  margin-right: 12px !important; }

/* Medium
 ========================================================================== */
:global(.cm-Margin--medium) {
  margin-bottom: 24px; }

:global(*) + :global(.cm-Margin--medium) {
  margin-top: 24px !important; }

:global(.cm-Margin--medium-top) {
  margin-top: 24px !important; }

:global(.cm-Margin--medium-bottom) {
  margin-bottom: 24px !important; }

:global(.cm-Margin--medium-left) {
  margin-left: 24px !important; }

:global(.cm-Margin--medium-right) {
  margin-right: 24px !important; }

/* Large
 ========================================================================== */
:global(.cm-Margin--large) {
  margin-bottom: 40px; }

:global(*) + :global(.cm-Margin--large) {
  margin-top: 40px !important; }

:global(.cm-Margin--large-top) {
  margin-top: 40px !important; }

:global(.cm-Margin--large-bottom) {
  margin-bottom: 40px !important; }

:global(.cm-Margin--large-left) {
  margin-left: 40px !important; }

:global(.cm-Margin--large-right) {
  margin-right: 40px !important; }

/* XLarge
 ========================================================================== */
:global(.cm-Margin--xlarge) {
  margin-bottom: 70px; }

:global(*) + :global(.cm-Margin--xlarge) {
  margin-top: 70px !important; }

:global(.cm-Margin--xlarge-top) {
  margin-top: 70px !important; }

:global(.cm-Margin--xlarge-bottom) {
  margin-bottom: 70px !important; }

:global(.cm-Margin--xlarge-left) {
  margin-left: 70px !important; }

:global(.cm-Margin--xlarge-right) {
  margin-right: 70px !important; }

/* Remove
 ========================================================================== */
:global(.cm-Margin--remove-bottom) {
  margin-bottom: 0 !important; }

:global(.cm-Margin--remove-top) {
  margin-top: 0 !important; }

:global(.cm-Margin--remove-left) {
  margin-left: 0 !important; }

:global(.cm-Margin--remove-rightj) {
  margin-right: 0 !important; }

:global(.cm-Margin--remove-vertical) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

:global(.cm-Margin--remove-adjacent) + :global(*),
:global(.cm-Margin--remove-first-child) > :global(:first-child) {
  margin-top: 0 !important; }

:global(.cm-Margin--remove-last-child) > :global(:last-child) {
  margin-bottom: 0 !important; }
</style>

Hello Svelte Margin
