<script>
  import { createEventDispatcher } from 'svelte';
  import { kit } from '../../tools/stores';

  const dispatch = createEventDispatcher();

  export let title = '';
  export let value = null;
  export let placeholder = '';
  export let options = [];

  $: selectedItem = options.find(o => o.value === value);

  function onClick() {
    let active = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
      cm-color="Primary / CDLLife Blue"
     class="cm-Icon">
<circle cx="8" cy="8" r="7.5" stroke="currentColor"/>
<circle cx="8" cy="8" r="4.5" fill="currentColor" stroke="currentColor"/>
</svg>
`,
      inactive = `
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
      cm-color="Primary / CDLLife Blue"
     class="cm-Icon">
<circle cx="8" cy="8" r="7.5" stroke="currentColor"/>
</svg>
`;

    $kit.ActionSheet({
      props: {
        buttons: options.map(o => {
          return {
            icon: o === selectedItem ? active : inactive,
            text: o.title || o.value,
            handler: () => {
              value = o.value;
              dispatch('change', { value: value });
            },
          };
        }),
      },
    });
    dispatch('change', { value: value });
  }
</script>

<style src="./Select.scss" global>:global(cm-kit[component='Select']) {
  margin-bottom: 16px;
  display: block; }
</style>

<div class="cm-ListItem" on:click={onClick}>
  <div class="cm-ListItem-middle">
    <div cm-font="Title / 16 Medium" cm-color="Neutral / Mine Shaft">
      {title}
    </div>
    <div cm-font="Body / 14 Regular" cm-color="Neutral / Storm Grey">
      {selectedItem ? selectedItem.short_title || selectedItem.title : placeholder}
    </div>
  </div>
  <div class="cm-ListItem-right">
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      cm-color="Neutral / Mine Shaft"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 0.21875L0.125 0.8125C0 0.96875 0 1.21875 0.125 1.34375L5.78125 7L0.125 12.6875C0 12.8125 0 13.0625 0.125 13.2188L0.75 13.8125C0.90625 13.9688 1.125 13.9688 1.28125 13.8125L7.84375 7.28125C7.96875 7.125 7.96875 6.90625 7.84375 6.75L1.28125 0.21875C1.125 0.0625 0.90625 0.0625 0.75 0.21875Z"
        fill="currentColor" />
    </svg>
  </div>
</div>
