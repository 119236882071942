<style src="./Padding.scss" global>:global(.cm-Padding) {
  padding: 16px; }

:global(.cm-Padding--small) {
  padding: 8px; }

:global(.cm-Padding--right) {
  padding-right: 16px; }

:global(.cm-Padding--top) {
  padding-top: 16px; }

:global(.cm-Padding--left) {
  padding-left: 16px; }

:global(.cm-Padding--bottom) {
  padding-bottom: 16px; }

/* Large
 ========================================================================== */
:global(.cm-Padding--large) {
  padding: 30px; }

/* Remove
 ========================================================================== */
:global(.cm-Padding--remove-vertical) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

:global(.cm-Padding--remove-horizontal) {
  padding-left: 0 !important;
  padding-right: 0 !important; }
</style>
