<style src="./ImageGrid.scss" global>:global(.cm-ImageGrid) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px; }
  :global(.cm-ImageGrid) :global(.cm-ImageGrid-image) {
    position: relative;
    height: 0px;
    padding-bottom: 100%;
    background: #f4f5f8 ; }
    :global(.cm-ImageGrid) :global(.cm-ImageGrid-image) > :global(img) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    :global(.cm-ImageGrid) :global(.cm-ImageGrid-image.cm-ImageGrid-image--button) :global(svg),
    :global(.cm-ImageGrid) :global(.cm-ImageGrid-image.cm-ImageGrid-image--button) :global(i) {
      color: #989aa2 ;
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      bottom: 0;
      transform: translate(-50%, -50%);
      height: 33px;
      font-size: 33px;
      width: auto;
      text-align: center; }
</style>
