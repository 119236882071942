<style src="./Avatar.scss" global>:global(.cm-Avatar) {
  font-size: 38px;
  height: 1em;
  width: 1em;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #989aa2 ; }
  :global(.cm-Avatar) > :global(:first-child) {
    display: inline-block; }
  :global(.cm-Avatar) > :global(img:first-child) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 1em;
    width: 1em;
    object-fit: cover;
    border-radius: 50%; }
</style>
