<style src="./Search.scss" global>:global(.cm-Search) {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0; }
  :global(.cm-Search) :global(.cm-Search--left) {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    padding-left: 14px; }
  :global(.cm-Search) :global(.cm-Search--right) {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 14px;
    padding-right: 14px; }

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
:global(.cm-Search-input::-webkit-search-cancel-button),
:global(.cm-Search-input::-webkit-search-decoration) {
  -webkit-appearance: none; }

/*
 * Removes placeholder transparency in Firefox.
 */
:global(.cm-Search-input::-moz-placeholder) {
  opacity: 1; }

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
:global(.cm-Search-input) {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 40px;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #262626; }

:global(.cm-Search-input:focus) {
  outline: none; }

/* Placeholder */
:global(.cm-Search-input:-ms-input-placeholder) {
  color: #989aa2 !important; }

:global(.cm-Search-input::placeholder) {
  color: #989aa2; }

/* Icon
 ========================================================================== */
/*
 * Remove default focus style
 */
:global(.cm-Search-icon:focus) {
  outline: none; }

/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
:global(.cm-Search) :global(.cm-Search-icon) {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999; }
  :global(.cm-Search) :global(.cm-Search-icon:before) {
    display: block;
    content: 'A';
    color: purple; }

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
:global(.cm-Search) :global(.cm-Search-icon:not(a):not(button):not(input)) {
  pointer-events: none; }

/*
 * Position modifier
 */
:global(.cm-Search) :global(.cm-Search-icon-flip) {
  right: 0;
  left: auto; }

/* Default modifier
 ========================================================================== */
:global(.cm-Search-default) {
  width: 180px; }

/*
 * Input
 */
:global(.cm-Search-default) :global(.cm-Search-input) {
  height: 34px;
  padding-left: 40px;
  padding-right: 34px;
  background: #f4f5f8; }

/* Focus */
:global(.cm-Search-default) :global(.cm-Search-input:focus) {
  background-color: #f4f5f8; }

/*
 * Icon
 */
:global(.cm-Search-default) :global(.cm-Search-icon) {
  width: 40px; }

:global(.cm-Search-default) :global(.cm-Search-icon:not(.cm-Search-icon-flip)) ~ :global(.cm-Search-input) {
  padding-left: 40px; }

:global(.cm-Search-default) :global(.cm-Search-icon-flip) ~ :global(.cm-Search-input) {
  padding-right: 40px; }

/*
 * Input
 */
:global(.cm-Search-navbar) :global(.cm-Search-input) {
  height: 40px;
  background: transparent;
  font-size: 16px; }

/*
 * Icon
 */
:global(.cm-Search-navbar) :global(.cm-Search-icon) {
  width: 40px; }

:global(.cm-Search-navbar) :global(.cm-Search-icon:not(.cm-Search-icon-flip)) ~ :global(.cm-Search-input) {
  padding-left: 40px; }

:global(.cm-Search-navbar) :global(.cm-Search-icon-flip) ~ :global(.cm-Search-input) {
  padding-right: 40px; }

/* Toggle
 ========================================================================== */
:global(.cm-Search-toggle) {
  color: purple; }

/* Hover + Focus */
:global(.cm-Search-toggle:hover),
:global(.cm-Search-toggle:focus) {
  color: green; }
</style>
