<style src="./MessageBar.scss" global>:global(.cm-MessageBar) {
  padding: 10px 0;
  border-color: #e1e1e1 ;
  border-style: solid ;
  border-width: 0.5px 0px 0px 0px; }
  :global(.cm-MessageBar) :global(.cm-MessageBar-text) {
    text-align: center;
    padding-bottom: 4px; }
  :global(.cm-MessageBar) :global(.cm-MessageBar-grid) {
    padding: 0 19px; }
  :global(.cm-MessageBar) :global(.cm-MessageBar-iconContainer) {
    display: inline-block;
    position: relative;
    width: 24px;
    align-self: flex-end; }
  :global(.cm-MessageBar) :global(.cm-MessageBar-icon) {
    font-size: 24px;
    margin-right: 20px !important;
    margin-left: 0px !important;
    max-width: 100%;
    margin-bottom: 3px; }
  :global(.cm-MessageBar) :global(.cm-MessageBar-image) {
    position: relative; }
    :global(.cm-MessageBar) :global(.cm-MessageBar-image) :global(.cm-MessageBar-imageRemove) {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      color: #fff; }
    :global(.cm-MessageBar) :global(.cm-MessageBar-image) :global(img) {
      border-radius: 10px;
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 120px; }
</style>
