<style src="./Messages.scss" global>:global(.cm-Message) :global(.cm-Message-body) {
  padding: 12px;
  max-width: 255px;
  border-radius: 12px;
  font-style: normal ;
  font-weight: normal ;
  font-size: 16px ;
  line-height: 16px ;
  color: #262626 ;
  background: #e1e1e1 ; }

:global(.cm-Message) :global(.cm-Message-image) {
  border-radius: 12px;
  overflow: hidden; }
  :global(.cm-Message) :global(.cm-Message-image) :global(.cm-Message-imageBox),
  :global(.cm-Message) :global(.cm-Message-image) :global(img) {
    display: block;
    border-radius: 12px;
    width: 175px;
    height: auto; }

:global(.cm-Message.cm-Message--self) :global(.cm-Message-body) {
  background: #2e5ff6 ;
  color: #ffffff ; }
</style>
