<script>
  import { createEventDispatcher } from 'svelte';
  import _ from 'lodash';

  export let tabs = [];
  export let active_tab = 0;

  const dispatch = createEventDispatcher();

  function findTabIndex(tab) {
    return _.findIndex(tabs, t => {
      return t.id === tab.id;
    });
  }
</script>

<style src="./SubNav.scss" global>:global(cm-kit[component='SubNav']) {
  height: 35.5px;
  display: block; }

:global(.SubNav) {
  height: 35.5px;
  padding: 0px 8px; }
  :global(.SubNav) :global(.cm-Grid-row) {
    padding: 0;
    justify-content: space-evenly; }
  :global(.SubNav) :global(.SubNav-item) {
    font-style: normal ;
    font-weight: normal ;
    font-size: 16px ;
    line-height: 20px ;
    color: #262626 ;
    height: 35.5px;
    text-align: center;
    position: relative;
    padding: 0 8px; }
  :global(.SubNav) :global(.SubNav-label) {
    font-style: normal ;
    font-weight: normal ;
    font-size: 16px ;
    line-height: 20px ;
    text-align: center;
    margin: 0 !important; }
  :global(.SubNav) :global(.SubNav-activeIndicator) {
    background: #2e5ff6;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px; }
</style>

<div class="cm-Grid SubNav">
  <div class="cm-Grid-row">
    {#each tabs as tab, i}
      <div
        on:click={e => {
          active_tab = i;
          dispatch('change', { value: tab });
        }}
        class="SubNav-item">
        <div class="SubNav-label">{tab.label}</div>
        {#if String(active_tab) === String(findTabIndex(tab))}
          <div class="SubNav-activeIndicator" />
        {/if}
      </div>
    {/each}
  </div>
</div>
