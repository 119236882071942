<style src="./Hints.scss" global>:global(.cm-Hint) {
  border-radius: 10px;
  text-align: left;
  padding: 12px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-size: 14px;
  box-shadow: 2px 2px 4px #989aa2;
  font-weight: 300; }
  :global(.cm-Hint[cm-color='Primary / CDLLife Blue']) {
    background: #2e5ff6 ;
    color: #fff;
    border: solid 1px #2e5ff6; }
    :global(.cm-Hint[cm-color='Primary / CDLLife Blue']) :global(.cm-Hint-arrow) {
      border-color: #2e5ff6 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Secondary / Dodger Blue']) {
    background: #3880ff ;
    color: #fff;
    border: solid 1px #3880ff; }
    :global(.cm-Hint[cm-color='Secondary / Dodger Blue']) :global(.cm-Hint-arrow) {
      border-color: #3880ff transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / Mine Shaft']) {
    background: #262626 ;
    color: #fff;
    border: solid 1px #262626; }
    :global(.cm-Hint[cm-color='Neutral / Mine Shaft']) :global(.cm-Hint-arrow) {
      border-color: #262626 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / Storm Grey']) {
    background: #73767f ;
    color: #fff;
    border: solid 1px #73767f; }
    :global(.cm-Hint[cm-color='Neutral / Storm Grey']) :global(.cm-Hint-arrow) {
      border-color: #73767f transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / Manatee']) {
    background: #989aa2 ;
    color: #fff;
    border: solid 1px #989aa2; }
    :global(.cm-Hint[cm-color='Neutral / Manatee']) :global(.cm-Hint-arrow) {
      border-color: #989aa2 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / Mercury']) {
    background: #e1e1e1 ;
    color: #fff;
    border: solid 1px #e1e1e1; }
    :global(.cm-Hint[cm-color='Neutral / Mercury']) :global(.cm-Hint-arrow) {
      border-color: #e1e1e1 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / Athens Grey']) {
    background: #f4f5f8 ;
    color: #fff;
    border: solid 1px #f4f5f8; }
    :global(.cm-Hint[cm-color='Neutral / Athens Grey']) :global(.cm-Hint-arrow) {
      border-color: #f4f5f8 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Neutral / White']) {
    background: #ffffff ;
    color: #fff;
    border: solid 1px #ffffff; }
    :global(.cm-Hint[cm-color='Neutral / White']) :global(.cm-Hint-arrow) {
      border-color: #ffffff transparent transparent transparent; }
  :global(.cm-Hint[cm-color='White']) {
    background: #ffffff ;
    color: #fff;
    border: solid 1px #ffffff; }
    :global(.cm-Hint[cm-color='White']) :global(.cm-Hint-arrow) {
      border-color: #ffffff transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Accent / Warning']) {
    background: #ffbd55 ;
    color: #fff;
    border: solid 1px #ffbd55; }
    :global(.cm-Hint[cm-color='Accent / Warning']) :global(.cm-Hint-arrow) {
      border-color: #ffbd55 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Accent / Danger']) {
    background: #ea4335 ;
    color: #fff;
    border: solid 1px #ea4335; }
    :global(.cm-Hint[cm-color='Accent / Danger']) :global(.cm-Hint-arrow) {
      border-color: #ea4335 transparent transparent transparent; }
  :global(.cm-Hint[cm-color='Accent / Success']) {
    background: #009859 ;
    color: #fff;
    border: solid 1px #009859; }
    :global(.cm-Hint[cm-color='Accent / Success']) :global(.cm-Hint-arrow) {
      border-color: #009859 transparent transparent transparent; }
  :global(.cm-Hint) :global(.cm-Hint-arrow) {
    border-width: 8px 8px 0px 8px;
    bottom: -8px;
    border-style: solid;
    position: absolute; }
    :global(.cm-Hint) :global(.cm-Hint-arrow.cm-Hint-arrow--left) {
      left: 10px; }
    :global(.cm-Hint) :global(.cm-Hint-arrow.cm-Hint-arrow--center) {
      left: 50%;
      transform: translateX(-50%); }
    :global(.cm-Hint) :global(.cm-Hint-arrow.cm-Hint-arrow--right) {
      right: 10px; }
  :global(.cm-Hint) :global(.cm-Hint-step) {
    float: right;
    font-weight: 500; }
  :global(.cm-Hint) :global(.cm-Hint-info) {
    float: left;
    font-weight: 300; }

:global(.cm-HintSpotlight) {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: table-cell;
  text-align: center;
  vertical-align: middle; }
</style>
