<style src="./Icon.scss" global>:global(.cm-Icon) {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  width: auto; }

:global(.cm-IconBadge) {
  position: relative; }
  :global(.cm-IconBadge) :global(.cm-Badge) {
    position: absolute;
    top: 0;
    right: 0;
    border: solid 1px #fff;
    min-height: 0.5em;
    min-width: 0.5em;
    font-size: inherit;
    transform: translate(10%, -60%);
    border-radius: 50%;
    padding: 0;
    background: #ea4335 ; }
</style>
