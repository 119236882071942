<style src="./Reply.scss" global>:global(.cm-Reply) {
  display: flex;
  margin-bottom: 24px; }
  :global(.cm-Reply) :global(.cm-Reply-left) {
    margin-right: 12px;
    width: auto; }
  :global(.cm-Reply) :global(.cm-Reply-middle) {
    flex-grow: 1;
    font-style: normal ;
    font-weight: normal ;
    font-size: 12px ;
    line-height: 16px ;
    color: #262626 ; }
    :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-user) {
      font-weight: 700;
      display: inline-block; }
    :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-link) {
      color: #2e5ff6 ; }
    :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) {
      display: flex;
      margin-top: 2px;
      margin-left: 1px;
      font-style: normal ;
      font-weight: normal ;
      font-size: 14px ;
      line-height: 20px ; }
      :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) :global(.cm-Reply-bottom-time) {
        flex-grow: 0;
        width: auto;
        margin-right: 17px;
        color: #73767f ;
        font-style: normal ;
        font-weight: normal ;
        font-size: 12px ;
        line-height: 16px ; }
      :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) :global(.cm-Reply-bottom-action) {
        flex-grow: 0;
        width: auto;
        margin-right: 17px;
        font-style: normal ;
        font-weight: normal ;
        font-size: 12px ;
        line-height: 16px ;
        color: #73767f ; }
        :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) :global(.cm-Reply-bottom-action) :global(i),
        :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) :global(.cm-Reply-bottom-action) :global(svg),
        :global(.cm-Reply) :global(.cm-Reply-middle) :global(.cm-Reply-bottom) :global(.cm-Reply-bottom-action) :global(.cm-Icon) {
          color: #262626 ;
          margin-left: 3px; }
  :global(.cm-Reply) :global(.cm-Reply-right) {
    margin-left: 12px;
    width: auto;
    color: #989aa2 ; }
    :global(.cm-Reply) :global(.cm-Reply-right) :global(i.fa),
    :global(.cm-Reply) :global(.cm-Reply-right) :global(i.fal),
    :global(.cm-Reply) :global(.cm-Reply-right) :global(i.fas) {
      font-size: 20px;
      line-height: 13px; }

:global(.cm-ReplyIntro) {
  font-style: normal ;
  font-weight: 500 ;
  font-size: 12px ;
  line-height: 16px ;
  color: #73767f ;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer; }
  :global(.cm-ReplyIntro) :global(.cm-ReplyIntro-line) {
    height: 1px;
    line-height: 1px;
    width: 32px;
    background: #73767f ;
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle; }
</style>
