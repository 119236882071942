<style src="./Width.scss" global>:global(.cm-Width-1-1) {
  width: 100%; }

:global(.cm-Width-1-2) {
  width: 50%; }

:global(.cm-Width-2-2) {
  width: 100%; }

:global(.cm-Width-1-3) {
  width: 33.33333%; }

:global(.cm-Width-2-3) {
  width: 66.66667%; }

:global(.cm-Width-3-3) {
  width: 100%; }

:global(.cm-Width-1-4) {
  width: 25%; }

:global(.cm-Width-2-4) {
  width: 50%; }

:global(.cm-Width-3-4) {
  width: 75%; }

:global(.cm-Width-4-4) {
  width: 100%; }

:global(.cm-Width-1-5) {
  width: 20%; }

:global(.cm-Width-2-5) {
  width: 40%; }

:global(.cm-Width-3-5) {
  width: 60%; }

:global(.cm-Width-4-5) {
  width: 80%; }

:global(.cm-Width-5-5) {
  width: 100%; }

:global(.cm-Width-1-6) {
  width: 16.66667%; }

:global(.cm-Width-2-6) {
  width: 33.33333%; }

:global(.cm-Width-3-6) {
  width: 50%; }

:global(.cm-Width-4-6) {
  width: 66.66667%; }

:global(.cm-Width-5-6) {
  width: 83.33333%; }

:global(.cm-Width-6-6) {
  width: 100%; }

:global(.cm-Width-1-7) {
  width: 14.28571%; }

:global(.cm-Width-2-7) {
  width: 28.57143%; }

:global(.cm-Width-3-7) {
  width: 42.85714%; }

:global(.cm-Width-4-7) {
  width: 57.14286%; }

:global(.cm-Width-5-7) {
  width: 71.42857%; }

:global(.cm-Width-6-7) {
  width: 85.71429%; }

:global(.cm-Width-7-7) {
  width: 100%; }

:global(.cm-Width-1-8) {
  width: 12.5%; }

:global(.cm-Width-2-8) {
  width: 25%; }

:global(.cm-Width-3-8) {
  width: 37.5%; }

:global(.cm-Width-4-8) {
  width: 50%; }

:global(.cm-Width-5-8) {
  width: 62.5%; }

:global(.cm-Width-6-8) {
  width: 75%; }

:global(.cm-Width-7-8) {
  width: 87.5%; }

:global(.cm-Width-8-8) {
  width: 100%; }

:global(.cm-Width-1-9) {
  width: 11.11111%; }

:global(.cm-Width-2-9) {
  width: 22.22222%; }

:global(.cm-Width-3-9) {
  width: 33.33333%; }

:global(.cm-Width-4-9) {
  width: 44.44444%; }

:global(.cm-Width-5-9) {
  width: 55.55556%; }

:global(.cm-Width-6-9) {
  width: 66.66667%; }

:global(.cm-Width-7-9) {
  width: 77.77778%; }

:global(.cm-Width-8-9) {
  width: 88.88889%; }

:global(.cm-Width-9-9) {
  width: 100%; }

:global(.cm-Width-1-10) {
  width: 10%; }

:global(.cm-Width-2-10) {
  width: 20%; }

:global(.cm-Width-3-10) {
  width: 30%; }

:global(.cm-Width-4-10) {
  width: 40%; }

:global(.cm-Width-5-10) {
  width: 50%; }

:global(.cm-Width-6-10) {
  width: 60%; }

:global(.cm-Width-7-10) {
  width: 70%; }

:global(.cm-Width-8-10) {
  width: 80%; }

:global(.cm-Width-9-10) {
  width: 90%; }

:global(.cm-Width-10-10) {
  width: 100%; }

:global(.cm-Width-1-11) {
  width: 9.09091%; }

:global(.cm-Width-2-11) {
  width: 18.18182%; }

:global(.cm-Width-3-11) {
  width: 27.27273%; }

:global(.cm-Width-4-11) {
  width: 36.36364%; }

:global(.cm-Width-5-11) {
  width: 45.45455%; }

:global(.cm-Width-6-11) {
  width: 54.54545%; }

:global(.cm-Width-7-11) {
  width: 63.63636%; }

:global(.cm-Width-8-11) {
  width: 72.72727%; }

:global(.cm-Width-9-11) {
  width: 81.81818%; }

:global(.cm-Width-10-11) {
  width: 90.90909%; }

:global(.cm-Width-11-11) {
  width: 100%; }

:global(.cm-Width-1-12) {
  width: 8.33333%; }

:global(.cm-Width-2-12) {
  width: 16.66667%; }

:global(.cm-Width-3-12) {
  width: 25%; }

:global(.cm-Width-4-12) {
  width: 33.33333%; }

:global(.cm-Width-5-12) {
  width: 41.66667%; }

:global(.cm-Width-6-12) {
  width: 50%; }

:global(.cm-Width-7-12) {
  width: 58.33333%; }

:global(.cm-Width-8-12) {
  width: 66.66667%; }

:global(.cm-Width-9-12) {
  width: 75%; }

:global(.cm-Width-10-12) {
  width: 83.33333%; }

:global(.cm-Width-11-12) {
  width: 91.66667%; }

:global(.cm-Width-12-12) {
  width: 100%; }

/* Auto */
:global(.cm-Width-auto) {
  width: auto; }

/* Expand */
:global(.cm-Width-expand) {
  flex: 1;
  min-width: 1px;
  flex-grow: 1; }

:global([class*='cm-child-width']) > :global(*) {
  box-sizing: border-box;
  width: 100%; }

:global(.cm-child-width-1-2) > :global(*) {
  width: 50%; }

:global(.cm-child-width-1-3) > :global(*) {
  width: calc(100% * 1 / 3.001); }

:global(.cm-child-width-1-4) > :global(*) {
  width: 25%; }

:global(.cm-child-width-1-5) > :global(*) {
  width: 20%; }

:global(.cm-child-width-1-6) > :global(*) {
  width: calc(100% * 1 / 6.001); }

:global(.cm-child-width-auto) > :global(*) {
  width: auto; }
</style>
