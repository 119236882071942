<style src="./Button.scss" global>:global(.cm-Button) {
  outline: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  color: #fff;
  vertical-align: middle; }
  :global(.cm-Button[cm-color='Primary / CDLLife Blue']) {
    background: #2e5ff6 ;
    color: #fff;
    border: solid 1px #2e5ff6; }
    :global(.cm-Button[cm-color='Primary / CDLLife Blue'].cm-Progress--alternate) {
      color: #2e5ff6 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Secondary / Dodger Blue']) {
    background: #3880ff ;
    color: #fff;
    border: solid 1px #3880ff; }
    :global(.cm-Button[cm-color='Secondary / Dodger Blue'].cm-Progress--alternate) {
      color: #3880ff ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / Mine Shaft']) {
    background: #262626 ;
    color: #fff;
    border: solid 1px #262626; }
    :global(.cm-Button[cm-color='Neutral / Mine Shaft'].cm-Progress--alternate) {
      color: #262626 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / Storm Grey']) {
    background: #73767f ;
    color: #fff;
    border: solid 1px #73767f; }
    :global(.cm-Button[cm-color='Neutral / Storm Grey'].cm-Progress--alternate) {
      color: #73767f ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / Manatee']) {
    background: #989aa2 ;
    color: #fff;
    border: solid 1px #989aa2; }
    :global(.cm-Button[cm-color='Neutral / Manatee'].cm-Progress--alternate) {
      color: #989aa2 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / Mercury']) {
    background: #e1e1e1 ;
    color: #fff;
    border: solid 1px #e1e1e1; }
    :global(.cm-Button[cm-color='Neutral / Mercury'].cm-Progress--alternate) {
      color: #e1e1e1 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / Athens Grey']) {
    background: #f4f5f8 ;
    color: #fff;
    border: solid 1px #f4f5f8; }
    :global(.cm-Button[cm-color='Neutral / Athens Grey'].cm-Progress--alternate) {
      color: #f4f5f8 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Neutral / White']) {
    background: #ffffff ;
    color: #fff;
    border: solid 1px #ffffff; }
    :global(.cm-Button[cm-color='Neutral / White'].cm-Progress--alternate) {
      color: #ffffff ;
      background: #fff; }
  :global(.cm-Button[cm-color='White']) {
    background: #ffffff ;
    color: #fff;
    border: solid 1px #ffffff; }
    :global(.cm-Button[cm-color='White'].cm-Progress--alternate) {
      color: #ffffff ;
      background: #fff; }
  :global(.cm-Button[cm-color='Accent / Warning']) {
    background: #ffbd55 ;
    color: #fff;
    border: solid 1px #ffbd55; }
    :global(.cm-Button[cm-color='Accent / Warning'].cm-Progress--alternate) {
      color: #ffbd55 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Accent / Danger']) {
    background: #ea4335 ;
    color: #fff;
    border: solid 1px #ea4335; }
    :global(.cm-Button[cm-color='Accent / Danger'].cm-Progress--alternate) {
      color: #ea4335 ;
      background: #fff; }
  :global(.cm-Button[cm-color='Accent / Success']) {
    background: #009859 ;
    color: #fff;
    border: solid 1px #009859; }
    :global(.cm-Button[cm-color='Accent / Success'].cm-Progress--alternate) {
      color: #009859 ;
      background: #fff; }
  :global(.cm-Button:active) {
    transform: translateY(1px);
    opacity: 0.93; }
  :global(.cm-Button.cm-Button--1) {
    border-radius: 30px;
    padding: 0 16px;
    height: 38px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 16px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--1.cm-Button--alternate) {
      background: #f4f5f8 ;
      border-color: #f4f5f8 ;
      border-style: solid ;
      font-style: normal ;
      font-weight: 500 ;
      font-size: 14px ;
      line-height: 16px ;
      color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ; }
      :global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ; }
  :global(.cm-Button.cm-Button--2) {
    border-radius: 30px;
    padding: 0 16px;
    height: 32px;
    line-height: 32px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 14px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--2.cm-Button--alternate) {
      background: #ffffff ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ;
        border-color: #2e5ff6 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ;
        border-color: #3880ff ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ;
        border-color: #262626 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ;
        border-color: #73767f ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ;
        border-color: #989aa2 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ;
        border-color: #e1e1e1 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ;
        border-color: #ffffff ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ;
        border-color: #ffffff ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ;
        border-color: #ffbd55 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ;
        border-color: #ea4335 ;
        border-style: solid ; }
      :global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ;
        border-color: #009859 ;
        border-style: solid ; }
  :global(.cm-Button.cm-Button--3) {
    border-radius: 30px;
    padding: 0 16px;
    height: 28px;
    line-height: 28px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 16px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--3.cm-Button--alternate) {
      color: #ffffff ; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        background: rgba(46, 95, 246, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        background: rgba(56, 128, 255, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        background: rgba(38, 38, 38, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        background: rgba(115, 118, 127, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        background: rgba(152, 154, 162, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        background: rgba(225, 225, 225, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        background: rgba(244, 245, 248, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Neutral / White']) {
        background: rgba(255, 255, 255, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='White']) {
        background: rgba(255, 255, 255, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Accent / Warning']) {
        background: rgba(255, 189, 85, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Accent / Danger']) {
        background: rgba(234, 67, 53, 0.5);
        border-color: transparent; }
      :global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color='Accent / Success']) {
        background: rgba(0, 152, 89, 0.5);
        border-color: transparent; }
  :global(.cm-Button.cm-Button--5) {
    border-radius: 4px;
    padding: 0 16px;
    line-height: 30px;
    height: 30px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 12px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--5.cm-Button--alternate) {
      background: #ffffff ;
      border-color: #2e5ff6 ;
      border-style: solid ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ; }
      :global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ; }
  :global(.cm-Button.cm-Button--6) {
    border-radius: 4px;
    padding: 0px 17px;
    line-height: 26px;
    height: 26px;
    font-style: normal ;
    font-weight: normal ;
    font-size: 14px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--6.cm-Button--alternate) {
      background: #f4f5f8 ;
      border-color: #f4f5f8 ;
      border-style: solid ;
      font-style: normal ;
      font-weight: normal ;
      font-size: 14px ;
      line-height: 16px ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ; }
      :global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ; }
  :global(.cm-Button.cm-Button--7) {
    border-radius: 4px;
    padding: 0 16px;
    height: 52px;
    line-height: 52px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 16px ;
    line-height: 16px ; }
    :global(.cm-Button.cm-Button--7.cm-Button--alternate) {
      background: #ffffff ;
      border-color: #2e5ff6 ;
      border-style: solid ;
      border-color: #262626 ;
      border-style: solid ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ; }
      :global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ; }
  :global(.cm-Button.cm-Button--8) {
    border-radius: 4px;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 16px ;
    line-height: 20px ;
    line-height: 40px; }
    :global(.cm-Button.cm-Button--8.cm-Button--alternate) {
      background: #f4f5f8 ;
      border-color: #f4f5f8 ;
      border-style: solid ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Primary / CDLLife Blue']) {
        color: #2e5ff6 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Secondary / Dodger Blue']) {
        color: #3880ff ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / Mine Shaft']) {
        color: #262626 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / Storm Grey']) {
        color: #73767f ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / Manatee']) {
        color: #989aa2 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / Mercury']) {
        color: #e1e1e1 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / Athens Grey']) {
        color: #f4f5f8 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Neutral / White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='White']) {
        color: #ffffff ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Accent / Warning']) {
        color: #ffbd55 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Accent / Danger']) {
        color: #ea4335 ; }
      :global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color='Accent / Success']) {
        color: #009859 ; }
  :global(.cm-Button.cm-Button-fab) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    padding: 5px 18px;
    font-size: 40px;
    font-weight: 200;
    line-height: 1; }
    :global(.cm-Button.cm-Button-fab[cm-color='Primary / CDLLife Blue']) {
      box-shadow: 0px 8px 16px rgba(46, 95, 246, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Secondary / Dodger Blue']) {
      box-shadow: 0px 8px 16px rgba(56, 128, 255, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / Mine Shaft']) {
      box-shadow: 0px 8px 16px rgba(38, 38, 38, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / Storm Grey']) {
      box-shadow: 0px 8px 16px rgba(115, 118, 127, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / Manatee']) {
      box-shadow: 0px 8px 16px rgba(152, 154, 162, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / Mercury']) {
      box-shadow: 0px 8px 16px rgba(225, 225, 225, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / Athens Grey']) {
      box-shadow: 0px 8px 16px rgba(244, 245, 248, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Neutral / White']) {
      box-shadow: 0px 8px 16px rgba(255, 255, 255, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='White']) {
      box-shadow: 0px 8px 16px rgba(255, 255, 255, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Accent / Warning']) {
      box-shadow: 0px 8px 16px rgba(255, 189, 85, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Accent / Danger']) {
      box-shadow: 0px 8px 16px rgba(234, 67, 53, 0.2); }
    :global(.cm-Button.cm-Button-fab[cm-color='Accent / Success']) {
      box-shadow: 0px 8px 16px rgba(0, 152, 89, 0.2); }
  :global(.cm-Button.cm-Button-edit) {
    line-height: 30px;
    height: 30px;
    width: 80px;
    border-radius: 15px;
    background: #73767f ; }
    :global(.cm-Button.cm-Button-edit) :global(i) {
      margin-right: 7px; }
  :global(.cm-Button.cm-Button--text) {
    /* 1 */
    padding: 0;
    background: none;
    position: relative;
    border: none !important;
    color: inherit; }

:global(.cm-ButtonList) :global(.cm-Button) {
  margin-right: 12px; }
</style>
