<style src="./Comments.scss" global>:global(.cm-Comments) {
  display: flex;
  margin-bottom: 24px; }
  :global(.cm-Comments) :global(.cm-Comments-left) {
    flex-grow: 0;
    width: auto; }
  :global(.cm-Comments) :global(.cm-Comments-right) {
    margin-left: 12px;
    flex-grow: 100; }
    :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) {
      border: 1px solid;
      border-color: #e1e1e1 ;
      border-style: solid ;
      border-radius: 15px;
      position: relative; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) {
        margin: 12px 12px;
        color: #262626 ; }
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comments-user) {
          font-style: normal ;
          font-weight: 500 ;
          font-size: 12px ;
          line-height: 16px ;
          margin-bottom: 5px;
          padding-right: 30px; }
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comments-comment) {
          font-style: normal ;
          font-weight: normal ;
          font-size: 12px ;
          line-height: 16px ; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(img) {
        border-radius: 10px; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) {
        position: absolute;
        right: 10px;
        top: 10px; }
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) > :global(svg),
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) :global(i),
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) :global(.cm-Icon) {
          display: inline-block;
          font-size: 20px;
          line-height: 12px; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) {
        right: 7px;
        bottom: 0px;
        transform: translateY(50%);
        position: absolute;
        border-radius: 12px;
        padding: 3px 6px;
        border: solid 1px;
        font-size: 10px;
        line-height: 11px;
        border-color: #e1e1e1 ;
        border-style: solid ;
        background: #fff; }
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) > :global(svg),
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) :global(i),
        :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) :global(.cm-Icon) {
          display: inline-block;
          margin-right: 3px; }
    :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) {
      display: flex;
      margin-top: 11px;
      margin-left: 1px;
      font-style: normal ;
      font-weight: normal ;
      font-size: 14px ;
      line-height: 20px ; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--time) {
        flex-grow: 0;
        width: auto;
        margin-right: 17px;
        color: #73767f ;
        font-style: normal ;
        font-weight: normal ;
        font-size: 12px ;
        line-height: 16px ; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--like) {
        flex-grow: 0;
        width: auto;
        margin-right: 17px;
        font-style: normal ;
        font-weight: 500 ;
        font-size: 12px ;
        line-height: 16px ; }
      :global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--reply) {
        flex-grow: 0;
        width: auto;
        font-style: normal ;
        font-weight: 500 ;
        font-size: 12px ;
        line-height: 16px ; }
</style>
