<script>
  import { createEventDispatcher } from 'svelte';

  import Password from './Types/Password.svelte';
  import PhoneInput from './Types/PhoneInput.svelte';
  import CodeInput from './Types/CodeInput.svelte';
  import MessageTextArea from './Types/MessageTextArea.svelte';

  export let type = 'password';
  export let placeholder = 'Password';
  export let value = '';
  export let showText = null;
  export let input_count = 3;
  export let rows = 1;

  const dispatch = createEventDispatcher();
</script>

<style src="./Input.scss" global>:global(.cm-Input) {
  background: #ffffff;
  margin: 0;
  -webkit-appearance: none;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  border: 0;
  transition: 0.2s ease-in-out;
  vertical-align: middle;
  display: inline-block; }
  :global(.cm-Input.cm-Input--flat) {
    height: 20px;
    appearance: none;
    display: inline-block;
    -webkit-box-flex: 1;
    border-radius: 0;
    color: #262626;
    border-bottom: 1px solid #e1e1e1;
    padding: 0;
    font-style: normal ;
    font-weight: normal ;
    font-size: 14px ;
    line-height: 20px ;
    flex: 1;
    background: 0 0; }
    :global(.cm-Input.cm-Input--flat[type='password']) {
      letter-spacing: 1.6px; }
    :global(.cm-Input.cm-Input--flat.cm-Input--flat--verificationPhone) {
      font-style: normal ;
      font-weight: 500 ;
      font-size: 20px ;
      line-height: 24px ;
      height: 24px;
      text-align: center;
      border-bottom: 1px solid #262626;
      letter-spacing: 2px; }
    :global(.cm-Input.cm-Input--flat.cm-Input--flat--digit) {
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      height: 36px;
      text-align: center;
      border-bottom: 2px solid #262626;
      width: 33px;
      margin-right: 11px; }
  :global(.cm-Input.cm-Input--outline) {
    height: 40px;
    border: 1px solid #e1e1e1;
    color: #262626;
    border-radius: 4px;
    box-sizing: border-box;
    font-style: normal ;
    font-weight: normal ;
    font-size: 16px ;
    line-height: 20px ; }
  :global(.cm-Input.cm-Input--textArea) {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 34px;
    font-style: normal ;
    font-weight: normal ;
    font-size: 14px ;
    line-height: 20px ;
    resize: none;
    overflow: hidden;
    min-height: 1em;
    max-height: 1000vh;
    width: 100%;
    border-radius: 40px;
    background: #f4f5f8 ; }
    :global(.cm-Input.cm-Input--textArea:focus) {
      background: #f4f5f8 ; }
    :global(.cm-Input.cm-Input--textArea.cm-Input--textArea--large) {
      border-radius: 10px; }

:global(.cm-InputMessageTextArea) {
  background: #f4f5f8 ;
  border-radius: 40px; }
  :global(.cm-InputMessageTextArea.cm-Input--InputMessageTextArea--large) {
    border-radius: 10px; }
  :global(.cm-InputMessageTextArea) :global(.cm-InputMessageTextArea-messageSubmitIcon) {
    margin-right: 9.5px;
    margin-bottom: 9px;
    display: inline-block;
    height: 14px;
    width: 14px;
    color: #73767f ; }
    :global(.cm-InputMessageTextArea) :global(.cm-InputMessageTextArea-messageSubmitIcon.is-active) {
      color: #2e5ff6 ; }
  :global(.cm-InputMessageTextArea) :global(.cm-InputMessageTextArea-image) {
    padding: 12px 12px 0px;
    position: relative; }

:global(.cm-Input:focus) {
  outline: none;
  background-color: #fff;
  caret-color: #2e5ff6; }

/*
 * Placeholder
 */
:global(.cm-Input::-ms-input-placeholder) {
  color: #73767f !important;
  font-style: normal ;
  font-weight: normal ;
  font-size: 16px ;
  line-height: 20px ;
  letter-spacing: normal !important; }

:global(.cm-Input::placeholder) {
  color: #73767f;
  font-style: normal ;
  font-weight: normal ;
  font-size: 16px ;
  line-height: 20px ;
  letter-spacing: normal !important; }

:global(.cm-Form--label) {
  font-style: normal ;
  font-weight: normal ;
  font-size: 14px ;
  line-height: 20px ;
  display: block;
  margin-bottom: 5px; }
</style>

{#if type === 'password'}
  <Password {value} {dispatch} {placeholder} {showText} />
{/if}

{#if type === 'phoneInput'}
  <PhoneInput />
{/if}

{#if type === 'codeInput'}
  <CodeInput {input_count} />
{/if}

{#if type === 'messageTextArea'}
  <MessageTextArea {value} {placeholder} {rows} {dispatch} />
{/if}
