<style src="./Card.scss" global>:global(.cm-Card) {
  background: #ffffff ;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px; }
  :global(.cm-Card.cm-Card--columns) {
    padding: 0;
    display: flex;
    align-items: stretch; }
    :global(.cm-Card.cm-Card--columns) > :global(*) {
      padding: 16px; }
      :global(.cm-Card.cm-Card--columns) > :global(*:first-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; }
      :global(.cm-Card.cm-Card--columns) > :global(*:last-child) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px; }
  :global(.cm-Card) :global(.Card-leftNumber) {
    text-align: center;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2e5ff6 ;
    color: #ffffff ;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 20px ;
    line-height: 24px ; }
  :global(.cm-Card.cm-Card--withHeader) {
    padding: 0; }
    :global(.cm-Card.cm-Card--withHeader) :global(.cm-Card-header) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden; }
    :global(.cm-Card.cm-Card--withHeader) :global(.cm-Card-body) {
      padding: 16px 16px; }
  :global(.cm-Card.cm-Card--margin-1) {
    margin-bottom: 4px; }
  :global(.cm-Card.cm-Card--margin-2) {
    margin-bottom: 8px; }
  :global(.cm-Card.cm-Card--margin-3) {
    margin-bottom: 12px; }
  :global(.cm-Card:last-child) {
    margin-bottom: 0; }
</style>
