<style src="./ProgressBar.scss" global>:global(.cm-ProgressBar) {
  display: inline-block;
  vertical-align: middle; }

:global(.cm-ProgressBar),
:global(.cm-ProgressBar-inner) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  width: 100%;
  height: 16px;
  position: relative; }
  :global(.cm-ProgressBar) :global(.cm-ProgressBar-inner),
  :global(.cm-ProgressBar-inner) :global(.cm-ProgressBar-inner) {
    background: #fff;
    position: absolute;
    left: 0;
    width: 0px;
    transition: width 0.2s;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
</style>
