<style src="./Badge.scss" global>:global(.cm-Badge) {
  padding: 2px 6px 0;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 500px;
  vertical-align: middle;
  min-height: 20px;
  min-width: 20px;
  font-style: normal ;
  font-weight: 500 ;
  font-size: 12px ;
  line-height: 16px ; }
</style>
