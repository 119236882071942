<style src="./Feedback.scss" global>:global(.cm-Feedback) {
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  background: rgba(38, 38, 38, 0.1);
  color: #262626; }
  :global(.cm-Feedback) :global(.cm-Feedback-message) {
    font-style: normal ;
    font-weight: 500 ;
    font-size: 14px ;
    line-height: 20px ;
    width: 100%;
    padding: 12px 0; }
  :global(.cm-Feedback) :global(.cm-Feedback-buttons) {
    padding-left: 16px;
    white-space: nowrap; }
    :global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(button) {
      margin-left: 9px; }
      :global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(button:first-child) {
        margin-left: 0; }
    :global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(.cm-Button--6) {
      color: #fff;
      background: #73767f; }
      :global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(.cm-Button--6.cm-Button--alternate) {
        color: #73767f;
        background: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
  :global(.cm-Feedback.cm-Feedback--success) {
    background: rgba(0, 152, 89, 0.1);
    color: #009859; }
    :global(.cm-Feedback.cm-Feedback--success) :global(.cm-Button--6) {
      color: #fff;
      background: #009859; }
      :global(.cm-Feedback.cm-Feedback--success) :global(.cm-Button--6.cm-Button--alternate) {
        color: #009859;
        background: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
  :global(.cm-Feedback.cm-Feedback--warning) {
    background: rgba(255, 189, 85, 0.1);
    color: #73767f; }
    :global(.cm-Feedback.cm-Feedback--warning) :global(.cm-Button--6) {
      color: #fff;
      background: #ffbd55; }
      :global(.cm-Feedback.cm-Feedback--warning) :global(.cm-Button--6.cm-Button--alternate) {
        color: #ffbd55;
        background: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
  :global(.cm-Feedback.cm-Feedback--error) {
    background: rgba(234, 67, 53, 0.1);
    color: #ea4335; }
    :global(.cm-Feedback.cm-Feedback--error) :global(.cm-Button--6) {
      color: #fff;
      background: #ea4335; }
      :global(.cm-Feedback.cm-Feedback--error) :global(.cm-Button--6.cm-Button--alternate) {
        color: #ea4335;
        background: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
  :global(.cm-Feedback.cm-Feedback--info) {
    background: rgba(56, 128, 255, 0.1);
    color: #3880ff; }
    :global(.cm-Feedback.cm-Feedback--info) :global(.cm-Button--6) {
      color: #fff;
      background: #3880ff; }
      :global(.cm-Feedback.cm-Feedback--info) :global(.cm-Button--6.cm-Button--alternate) {
        color: #3880ff;
        background: #f4f5f8 ;
        border-color: #f4f5f8 ;
        border-style: solid ; }
</style>
